
import { get, post } from '../request'
/**订单管理模块 */
export default {
    /**
    * @description 订单支付类型 所有
    * @returns Object
    */
    getOrderPayWaysAll() {
        return get(`/payWay/selectPayWayList`)
    },


    /**
     * @description 订单查询所有
     * @param {String} params 
     * @returns Object
     */
    getOrderSelectAll(params) {
        return get(`/admin/order/selectAll`, params, true)
    },
    /**
     * @description 订单查询biPlate
     * @param {String} params 
     * @returns Object
     */
    getOrderSelectByPlate(params) {
        return get(`/admin/order/selectByPlate`, params, true)
    },
    /**
     * @description 订单退款轮询（单一）
     * @param {String} params 
     * @returns Object
     */
    getOrderRefundLunxunByNo(params) {
        return get(`/admin/order/orderRefundQuery?refundRequestNo=${params}`)
    },
    /**
     * @description 订单退款轮询 （混合支付）
     * @param {String} params 
     * @returns Object
     */
    hxRefundQueryOrderByNo(params) {
        return get(`/admin/order/hxRefundQuery?outOrderNo=${params}`)
    },



    /**
     * @description 订单退款查询byid
     * @param {String} params 
     * @returns Object
     */
    getOrderRefundSelectById(params) {
        return get(`/admin/order/selectById?id=${params}`)
    },
    /**
    * @description 订单退款byid
    * @param {String} params 
    * @returns Object
    */
    postOrderRefundById(params) {
        return post(`/admin/order/tradeRefund/${params}`)
    },
    /**
   * @description 订单 可退款查询byid
   * @param {String} params 
   * @returns Object
   */
    getOrderRefundAmountById(params) {
        return get(`/admin/order/refundAmount?id=${params}`)
    },
    /**
      * @description 充值订单退款byid
      * @param {String} params 
      * @returns Object
      */
    postOrderCardTradeRefundById(params) {
        return post(`/admin/order/cardTradeRefund/${params}`)
    },
    /**
     * @description 充值订单退款查询
     * @param {String} params 
     * @returns Object
     */
    getOrderCardTradeRefundQuery(params) {
        return get(`/admin/order/cardTradeRefundQuery?refundRequestNo=${params}`)
    },
    /**
     * @description 充值记录查询按卡ID
     * @param {String} params 
     * @returns Object
     */
    getSelectByCardId(params) {
        return get(`/cardTopUpRecord/selectByCardId/${params}`)
    },

}