import { ElLoading } from "element-plus";

/* 全局请求 loading(服务方式调用) */
let loadingInstance = typeof ElLoading.service;

/**
 * @description 开启 Loading
 * */
const startLoading = () => {
  loadingInstance = ElLoading.service({
    target: ".manage-area-main", //显示加载状态的元素范围
    fullscreen: false, // 是否全屏
    lock: true,
    text: "正在加载...", // 加载中提示文字
    // spinner: " ",
    customClass: "custom-loading", // 使用自定义 CSS 类
    background: "rgba(255, 255, 255, 0.9)",
  });
};

/**
 * @description 结束 Loading
 * */
const endLoading = () => {
  loadingInstance.close();
};

/**
 * @description 显示全屏加载
 * */
let needLoadingRequestCount = 0;
export const showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
};

/**
 * @description 隐藏全屏加载
 * */
export const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
};
