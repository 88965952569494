import { get, post } from "../request";
/**员工管理模块 */
export default {
  /**
   * @description 查询所有员工
   *  @param {Object} params
   *  @returns Object
   *  */
  getEmployeesAll(params) {
    return get("/employees/selectAll", params, true);
  },
  /**
   * @description 查询所有员工 不分页
   *  @param {Object} params
   *  @returns Object
   *  */
  getEmployeesAllNoPage() {
    return get("/employees/selectAllNotPage");
  },
  /**
   * @description 删除员工 byid
   *  @param {String} params
   *  @returns Object
   *  */
  getEmployeesDelById(params) {
    return get(`/employees/delete/${params}`);
  },
  /**
   * @description 查询员工 byid
   *  @param {String} params
   *  @returns Object
   *  */
  getEmployeesSelectById(params) {
    return get(`/employees/select/${params}`);
  },
  /**
   * @description 新增员工
   *  @param {String} params
   *  @returns Object
   *  */
  postEmployeesAdd(params) {
    return post(`/employees/add`, params);
  },
  /**
   * @description 绑定 员工小程序
   *  @param {String} params
   *  @returns Object
   *  */
  postEmployeesAddMiniId(params) {
    return get(`/wxUser/setEmployees`, params);
  },
  /**
   * @description 解绑 员工小程序
   *  @param {String} params
   *  @returns Object
   *  */
  postEmployeesDelMiniId(params) {
    return get(`/wxUser/delEmployees`, params);
  },

  /**
   * @description 员工加油统计
   *  @param {String} params
   *  @returns Object
   *  */
  getEmployeesAddOil(params) {
    return get(`/employeesAddOil/selectAllAddOil`, params, true);
  },
};
