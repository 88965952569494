
import { get, post } from '../request'
/** 用户会员优惠券 模块 */
export default {

    /**
     * @description 查询所有会员
     * @param {String|Object} params 
     * @returns Object
     */
    postAllWxUser(params) {
        return post("/wxUser/selectAl", params, true)
    },
    /**
    * @description 查询所有会员信息
    * @param {String|Object} params 
    * @returns Object
    */
    getAllWxUserInfo(params) {
        return get("/wxUser/selectAll", params)
    },
    /**
     * @description 查询所有会员等级 不分页
     * @returns Object
     */
    getAllWxUserLeve() {
        return get("/member/level/selectAllNotPage")
    },
    /**
     * @description 查询所有会员等级 
     *  @param {Object} params 
     * @returns Object
     */
    getAllMemberLeve(params) {
        return get("/member/level/selectAll", params, true)
    },
    /**
    * @description 停用会员卡
    * @param {String} params 
    * @returns Object
    */

    getStopCard(params) {
        return get(`/card/stopCard/${params}`)
    },

    /**
       * @description 查询会员等级
       *  @param {String} params 
       * @returns Object
       */
    getMemberLeveById(params) {
        return get(`/member/level/select/${params}`)
    },
    /**
       * @description 修改会员等级
       *  @param {Object} params 
       * @returns Object
       */
    postUpdateMemberLeve(params) {
        return post('/member/level/update', params)
    },
    /**
     * @description 查询会员等级优惠
     *  @param {String} params 
     * @returns Object
     */
    getMemberLeveCouponById(params) {
        return get(`/member/level/memberLevelCoupon/${params}`)
    },
    /**
       * @description 添加会员等级优惠
       *  @param {Object} params 
       * @returns Object
       */
    postAddMemberLeveCoupon(params) {
        return post('/member/level/addMember', params)
    },
    /**
     * @description 会员等级优惠删除
     *  @param {String} params 
     * @returns Object
     */
    getMemberLeveCouponDelById(params) {
        return get(`/memberLevelCoupon/delete/${params}`)
    },
    /**
        * @description 会员等级优惠所有
        *  @param {String} params 
        * @returns Object
        */
    getMemberLeveCouponAll(params) {
        return get(`/memberLevelCoupon/selectAll`, params)
    },

    /**
      * @description 设置用户查看车队权限
      * @param {String|Object} params 
      * @returns Object
      */
    getSetTopIdById(params) {
        return get(`/wxUser/setTopId?${params}`)
    },
    /**
      * @description 取消用户查看车队权限
      * @param {String|Object} params 
      * @returns Object
      */
    getDelTopIdById(params) {
        return get(`/wxUser/delTopId?${params}`)
    },
    /**
    * @description 查询所有个人卡
    * @param {String|Object} params 
    * @returns Object
    */
    getAllPerSonCard(params) {
        return get("/individuals/selectAll", params, true)
    },
    /**
     * @description 查询所有充值卡
     * @param {String|Object} params 
     * @returns Object
     */
    getAllRechageCard(params) {
        return get("/card/selectAll", params)
    },
    /**
       * @description 查询个人卡by ID
       * @param {String|Object} params 
       * @returns Object
       */
    getPerSonCardById(params) {
        return get(`/individuals/selectById?topId=${params}`)
    },

    /**
     * @description 添加个人卡
     * @param {Object} params 
     * @returns Object
     */
    postIndividualsAdd(params) {
        return post("/individuals/add", params)
    },

    /**
    * @description 加油卡查询select获取车队列表
    * @returns Object
    */
    getMotorcadeMenuAs() {
        return get("/motorcade/motorcadeMenuAs", {})
    },

    /**
         * @description 添加车队卡 副卡
         * @param {Object} params 
         * @returns Object
         */
    postMotorcadeAddAssistantCard(params) {
        return post("/motorcade/addAssistantCard", params)
    },

    /**
     * @description 添加车队卡
     * @param {Object} params 
     * @returns Object
     */
    postMotorcadeAdd(params) {
        return post("/motorcade/add", params)
    },

    /**
     * @description 车队卡主卡充值
     * @param {Object} params 
     * @returns Object
     */
    postRechargeTheMainCard(params) {
        return post("/motorcade/rechargeTheMainCard", params)
    },

    /**
     * @description 查询车队卡by ID
     * @param {String|Object} params 
     * @returns Object
     */
    getMotorcadeCardById(params) {
        return get(`/motorcade/selectByTopId?topId=${params}`)
    },

    /**
     * @description 查询司机卡
     * @param {String|Object} params 
     * @returns Object
     */
    getAllDriverCard(params) {
        return get("/card/queryCard", params, true)
    },
    /**
    * @description 修改司机卡
    * @param {String|Object} params 
    * @returns Object
    */
    postUpdateDriverCardInfo(params) {
        return post("/motorcade/updateDriverCardInfo", params)
    },
    /**
     * @description 分配余额
     * @param {String|Object} params 
     * @returns Object
     */
    postAllocatedBalance(params) {
        return post("/motorcade/allocatedBalance", params)
    },
    /**
    * @description 获取待分配信息
    * @param {String|Object} params 
    * @returns Object
    */
    getAllocatedBalanceWeb(params) {
        return get(`/motorcade/allocatedBalanceWeb?topId=${params}`, {}, true)
    },

    /**
    * @description 管理账户充值记录
    * @param {String|Object} params 
    * @returns Object
    */
    getManageAccountTopUpRecord(params) {
        return get("/motorcade/manageAccountTopUpRecord", params, true)
    },

    /**
     * @description 消费记录
     * @param {String|Object} params 
     * @returns Object
     */
    getExpenseCalendar(params) {
        return get("/motorcade/expenseCalendar", params, true)
    },
    /**
      * @description 财务信息
      * @param {String|Object} params 
      * @returns Object
      */
    getFinancialInformation(params) {
        return get(`/motorcade/financialInformation?topId=${params}`, {}, true)
    },
    /**
        * @description 分配余额记录
        * @param {String|Object} params 
        * @returns Object
        */
    getAllocatedBalanceRecord(params) {
        return get("/motorcade/allocatedBalanceRecord", params, true)
    },



    /**
    * @description 获取修改司机卡信息by ID
    * @param {String|Object} params 
    * @returns Object
    */
    getDriverCardInfoById(params) {
        return get(`/motorcade/selectDriverCardInfo?id=${params}`)
    },
    /**
     * @description 查询司机卡by ID
     * @param {String|Object} params 
     * @returns Object
     */
    getDriverCardById(params) {
        return get(`/motorcade/selectDriverCard?topId=${params}`)
    },
    /**
    * @description 查询所有充值退款记录
    * @param {String|Object} params 
    * @returns Object
    */
    getAllCardTopUpRecord(params) {
        return get("/cardTopUpRecord/selectAll", params, true)
    },
    /**
    * @description 查询所有充值记录
    * @param {String|Object} params 
    * @returns Object
    */
    getAllcardRecord(params) {
        return get("/recordByCard/selectAll", params, true)
    },
    /**
        * @description 查询充值记录byId
        * @param {String|Object} params 
        * @returns Object
        */
    getCardRecordById(params) {
        return get(`/cardTopUpRecord/selectById/${params}`)
    },

    /**
        * @description 查询所有使用记录
        * @param {String|Object} params 
        * @returns Object
        */
    getRecordByCardAll(params) {
        return get(`/recordByCard/selectAll`, params, true)
    },
    /**
    * @description 查询所有充值类型
    * @param {String|Object} params 
    * @returns Object
    */
    getAllCardTopUp(params) {
        return get("/cardTopUp/selectAll", params, true)
    },
    /**
        * @description 充值开启关闭
        * @param {String|Object} params 
        * @returns Object
        */
    getCardTopUpById(params) {
        return get(`/cardTopUp/openById/${params}`)
    },
    /**
    * @description 充值删除
    * @param {String|Object} params 
    * @returns Object
    */
    getCardTopUpDelById(params) {
        return get(`/cardTopUp/delete/${params}`)
    },

    /**
      * @description 增加充值类型
      * @param {Object} params 
      * @returns Object
      */
    postCardTopUpAdd(params) {
        return post("/cardTopUp/add", params)
    },

    /**
       * @description 充值按id查询
       * @param {String|Object} params 
       * @returns Object
       */
    getCardTopUpSelectById(params) {
        return get(`/cardTopUp/selectById/${params}`)
    },
    /**
       * @description 查询车队列表
       * @returns Object
       */
    getMotorcade() {
        return get("/motorcade/selectMotorcadeMenu")
    },
    /**
      * @description 查询车队信息列表
      * @param {String|Number} params 
      * @returns Object
      */
    getSelectMotorcade(params) {
        return get("/motorcade/selectMotorcade", params, true)
    },
    /**
     * @description 添加活动
     * @param {Object} params 
     * @returns Object
     */
    postActivityAdd(params) {
        return post("/topUp/Activity/add", params)
    },
    /**
     * @description 通过活动id创建一个活动
     * @param {Object} params 
     * @returns Object
     */
    getActivityAddById(params) {
        return get(`/topUp/Activity/selectActivity?id=${params}`)
    },

    /**
    * @description 充值活动查询卡by 卡种
    * @param {String|Object} params 
    * @returns Object
    */
    getActivitytopUpSelectByState(params) {
        return get(`/topUp/Activity/selectTop?state=${params}`)
    },
    /**
       * @description 充值活动查询卡by 车队id
       * @param {String|Object} params 
       * @returns Object
       */
    getActivitytopUpSelectByTopId(params) {
        return get(`/topUp/Activity/selectAll?topId=${params}`)
    },
    /**
     * @description 充值活动删除by id
     * @param {String|Object} params 
     * @returns Object
     */
    getActivitytopUpDelById(params) {
        return get(`/topUp/Activity/del?id=${params}`)
    },
    /**
       * @description 会员充值记录
       * @param {String|Object} params 
       * @returns Object
       */
    wxUserRechargeRecords(params) {
        return get(`/wxUser/topUpRecordByWxUserId`, params)
    },
    /**
    * @description 会员消费油卡
    * @param {String|Object} params 
    * @returns Object
    */
    wxUserConsumeOilCard(params) {
        return get(`/wxUser/cardByUserId`, params)
    },

    /**
       * @description 会员订单记录
       * @param {String|Object} params 
       * @returns Object
       */
    wxUserOilOrderRecords(params) {
        return get(`/wxUser/selectByUserId`, params)
    },
    /**
    * @description 充值记录查询按卡ID
    * @param {String} params 
    * @returns Object
    */
    getSelectRechargeByCardId(params) {
        return get(`/cardTopUpRecord/selectByCardId`, params)
    },
    /**
      * @description 成长值赠送
      * @param {Object} params 
      * @returns Object
      */
    postGiveGrowthByWxUserId(params) {
        return post("/member/level/giftGrowth", params)
    },
    /**
  * @description 积分赠送
  * @param {Object} params 
  * @returns Object
  */
    postGivePointsByWxUserId(params) {
        return post("/member/level/giftPoints", params)
    },
   /**
       * @description 会员卡台账
       * @param {Object} params 
       * @returns Object
       */
   getAggregateQuery(params) {
    return get("/card/aggregateQuery", params,true)
},
}