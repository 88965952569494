import { get, post } from '../request'
/**设备管理模块 */
export default {
    /**  油枪管理 */
    /**
     * @description 获取所有油枪
     */
    getOilGunAll(params) {
        return get("/oilGun/selectAll", params, true)
    },
    /**
    * 删除油枪byid
    */
    getOilGunDelById(params) {
        return get("/oilGun/delete/" + params)
    },
    /**
       *查油枪信息byid
       */
    getOilGunInfoById(params) {
        return get("/oilGun/select/" + params)
    },

    /**
       * 修改油枪信息
       */
    postOilGunUpdate(params) {
        return post("/oilGun/update", params)
    },

    /** 收银机app管理 */
    /**
       * @description 获取所有收银机app
       */
    getCashierAppAll(params) {
        return get("/cashierApp/selectAll", params, true)
    },
    /**
           * @description shanc收银机app
           */
    getCashierAppDel(params) {
        return get("/cashierApp/delete/" + params)
    },
    /**
     * @description shanc收银机app 信息查询
     */
    getCashierAppInfo(params) {
        return get("/cashierApp/select/" + params)
    },

    /**
       * @description 添加/修改 收银app
       */
    postAppAddEdit(params) {
        return post("/cashierApp/add", params)
    },



    /** 油价管理 */
    /**
        * @description 获取所有油类型
        */
    getOilTypeAll(params) {
        return get("/oil/type/selectAll", params, true)
    },
    /**
   * @description 获取所有预计改价
   */
    getOilPredictAll(params) {
        return get("/oil/type/selectPredictAll", params, true)
    },
    /**
     * @description 删除改价byid
     */
    getPredictPriceByIdDel(params) {
        return get("/oil/type/deleteChancePrice/" + params)
    },
    /**
       * @description 查询改价信息byid
       */
    getPredictPriceByIdInfo(params) {
        return get("/oil/type/selectChance/" + params)
    },
    /**
       * @description 添加/修改 改价
       */
    postPredictPriceAddEdit(params) {
        return post("/oil/type/addChancePrice", params)
    },
    /**
      * @description 获取所有设备列表不分页
      * @returns Object
      */
    getDeviceListNoPage() {
        return get(`/device/selectAllNotPage`)
    },

    /** 大屏app管理 */

    /**
       * @description 获取所有大屏app
       */
    getScreenAppAll(params) {
        return get("/screenApp/selectAll", params, true)
    },
    /**
           * @description 删除大屏app
           */
    getScreenAppDel(params) {
        return get("/screenApp/delete/" + params)
    },
    /**
     * @description 大屏app 信息查询
     */
    getScreenAppInfo(params) {
        return get("/screenApp/select/" + params)
    },

    /**
       * @description 添加/修改 大屏app
       */
    postAppAddEdit(params) {
        return post("/screenApp/add", params)
    },
    /** 大屏管理 */

    /**
       * @description 获取所有大屏设备
       */
    getScreenDeviceAll(params) {
        return get("/device/selectAll", params, true)
    },
    /**
           * @description 删除大屏
           */
    getScreenDeviceDel(params) {
        return get("/device/delete/" + params)
    },
    /**
     * @description 大屏app 信息查询
     */
    getScreenDeviceInfo(params) {
        return get("/device/select/" + params)
    },

    /**
       * @description 添加/修改 大屏
       */
    postScreenDeviceAddEdit(params) {
        return post("/device/add", params)
    },

    /** 车辆管理 */

    /**
       * @description 获取所有车辆
       */
    getCarAll(params) {
        return get("/car/selectAll", params, true)
    },
    /**
           * @description 删除车辆
           */
    getCarDel(params) {
        return get("/car/delete/" + params)
    },
    /**
     * @description 车辆 信息查询
     */
    getCarInfo(params) {
        return get("/car/select/" + params)
    },

    /**
       * @description 添加/修改 车辆
       */
    postCarAddEdit(params) {
        return post("/car/update", params)
    },

    /** 摄像头管理 */

    /**
       * @description 获取所有摄像头
       */
    getCameraAll(params) {
        return get("/camera/selectAll", params, true)
    },
    /**
           * @description 删除摄像头
           */
    getCameraDel(params) {
        return get("/camera/delete/" + params)
    },
    /**
     * @description 摄像头 信息查询
     */
    getCameraInfo(params) {
        return get("/camera/select/" + params)
    },

    /**
       * @description 添加/修改 摄像头
       */
    postCameraAddEdit(params) {
        return post("/camera/update", params)
    },
}
