import { get, post } from '../request'
/*礼品模块 */
export default {


    /**
     * @description 礼品查询
     * @param {String} params 
     * @returns Object
     */
    getGiftSelect(params) {
        return get(`/gift/select/${params}`)
    },
    /**
     * @description 礼品添加 /修改
     * @param {String} params 
     * @returns Object
     */
    postGiftAddOrUpdate(params) {
        return post(`/gift/updateOrAdd`, params)
    },



    /**
    * @description 礼品查询所有
    * @param {String} params 
    * @returns Object
    */
    getGiftSelectAll(params) {
        return get(`/gift/selectAll`, params, true)
    },
    /**
     * @description 礼品删除byid
     * @param {String} params 
     * @returns Object
     */
    getGiftDelById(params) {
        return get(`/gift/delete/${params}`)
    },


    //安徽 礼包
    /**
      * @description 礼包查询
      * @param {String} params 
      * @returns Object
      */
    getGiftSelectToAH(params) {
        return get(`/acPack/getAcPackDetails?id=${params}`)
    },
    /**
     * @description 礼包添加 
     * @param {String} params 
     * @returns Object
     */
    postGiftAddToAH(params) {
        return post(`/acPack/addAcPack`, params)
    },
    /**
    * @description 礼包修改
    * @param {String} params 
    * @returns Object
    */
    postGiftEditToAH(params) {
        return post(`/acPack/updateAcPack`, params)
    },
    /**
    * @description 礼包删除
    * @param {String} params 
    * @returns Object
    */
    postGiftDelToAH(params) {
        return get(`/acPack/deleteAcPack?id=${params}`)
    },


    /**
    * @description 礼包查询所有
    * @param {String} params 
    * @returns Object
    */
    getGiftSelectAllToAH(params) {
        return get(`/acPack/selectAcPackAll`, params, true)
    },

    /**
         * @description 礼包订单记录查询所有
         * @param {String} params 
         * @returns Object
         */
    getGiftSelectAllOrderRecouds(params) {
        return get(`/acPackOrder/selectAll`, params, true)
    },
    /**
     * @description 礼包订单退款
     * @param {String} params 
     * @returns Object
     */
    getGiftOrderRecouds(params) {
        return get(`/acPackOrder/refundPackOrder`, params, true)
    },

    /**
    * @description 礼包销售统计
    * @param {String} params 
    * @returns Object
    */
    getGiftPackStatistics(params) {
        return get(`/acPackOrder/packOrderSta`, params, true)
    },

      /**
    * @description 礼包补发优惠券
    * @param {String} params 
    * @returns Object
    */
      getGiftPackReissueCoupon(params) {
        return get(`/acPackOrder/couponRetransmission`, params )
    },
      /**
    * @description 礼包核销
    * @param {String} params 
    * @returns Object
    */
      getGiftPackWriteOff(params) {
        return get(`/acPackOrder/verificationPack`, params)
    },
}