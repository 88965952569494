
import { get, post } from '../request'
/**油站管理模块 */
export default {
    /**油站管理模块 */
    /**
     * @description 获取所有油站
     *  @param {String} params 
     *  @returns Object
     *  */
    getStationAll() {
        return get(`/sys/tenant/tenantList`)
    },
    /**
     * @description 查找油站 by tennatid
     *  @param {String} params 
     *  @returns Object
     *  */
    queryStationByTenantId(params) {
        return get(`/sys/tenant/${params}`)
    },
    /**
    * @description 删除油站 by tennatid
    *  @param {String} params 
    *  @returns Object
    *  */
    delStationByTenantId(params) {
        return get(`/sys/tenant/delete/${params}`)
    },
    /**
    * @description 查询油站列表id集合 by 用户id
    *  @param {String} params 
    *  @returns Object
    *  */
    queryStationListByUserId(params) {
        return get(`/sys/tenant/menus/${params}`, {}, true)
    },
    /**
       * @description 新增或修改油站 
       *  @param {String} params 
       *  @returns Object
       *  */
    postStationAddEdit(params) {
        return post(`/sys/tenant/save`, params)
    },
    /**
          * @description 更新分配油站租赁信息 
          *  @param {String} params 
          *  @returns Object
          *  */
    postUpdateDispenseStione(userId, params) {
        return post(`/sys/tenant/updateMenus/${userId}`, params)
    },

        /**
     * @description 不分页查油枪列表
     *  @param {String} params 
     *  @returns Object
     *  */
        getOilGunListNoPage() {
            return get(`/oilGun/selectAllNotPage`)
        },
}