<template>
  <router-view />
</template>
<script setup>

</script>
<style>
html,
body,
#app {
  height: 100%;
}

.app-container {
  padding: 20px
}

/* 加载进度条颜色 */
/* #nprogress .bar {
  background: rgb(71, 221, 34) !important;

} */

/* 加载icon 颜色 */
/* #nprogress .spinner-icon {
  border-top-color: rgb(93, 221, 34) !important;
  border-left-color: rgb(71, 221, 34) !important;
} */
</style>
